import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Dropdown, Form, Input, InputNumber, Menu, Modal, Row, Select, Spin, Table, message as waw } from 'antd';
import { Tabs } from 'antd';
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { rulesGeneral, toRp } from '../../helpers/general_helper';
import { env } from '../../configs/EnvironmentConfig';
import { formatterNumber, parserNumber } from '../../helpers/input_format';
import axios from 'axios';
import dayjs from 'dayjs';
import moment from 'moment';
import Dragger from 'antd/es/upload/Dragger';


const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const RequestMaterial = (props, { callback }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [isCsvInput, setIsCsvInput] = useState(false);
  const [isCsvDisabled, setIsCsvDisabled] = useState(false);

  // const handleIsCsvOpen = (value, type) => {
  //   if (value) {
  //     setIsCsvDisabled(true);
  //   } else {
  //     setIsCsvDisabled(false);
  //   }
  // }

  const handleIsCsvOpen = (value, type) => {
    setIsCsvDisabled(true);
  }
  const handleIsCsvInput = (value, type) => {
    if (value) {
      setIsCsvInput(true);
    } else {
      setIsCsvInput(false);
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [formInputs, setFormInputs] = useState([{ id: 1, value: '' }]);
  const items = ['Item 1', 'Item 2', 'Item 3'];
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const handleAddInput = () => {
    const newInput = {
      id: formInputs.length + 1,
      value: ''
    };
    setCurrentItemIndex((prevIndex) => (prevIndex + 1) % items.length);
    setFormInputs([...formInputs, newInput]);
  };

  const handleInputChange = (id, value) => {
    const updatedInputs = formInputs.map(input => {
      if (input.id === id) {
        return { ...input, value };
      }
      return input;
    });

    setFormInputs(updatedInputs);
  };

  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [orderStatus, setOrderStatus] = useState('confirm', 'loading', 'success');
  const [resultModalVisible, setResultModalVisible] = useState(false);

  const handleConfirmOrder = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setOrderStatus('confirm');
      waw.success('Request Berhasil Di Ajukan');
      setResultModalVisible(true);
      window.location = '/';
    }, 2000);
  };

  const handleResultModalClose = () => {
    setResultModalVisible(false);
    handleCancel();
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [form] = Form.useForm();
  const [apiData, setApiData] = useState([]);
  const [formData, setFormData] = useState({});


  const onFinishRequest = async () => {
    const values = await form.validateFields();
    setFormData(values);
    values.total_price_all = Object.keys(values).length === 0 ? '-' : values?.items?.length > 0 ? values?.items?.reduce((a, b) => a + (parseInt(b.total_price) || 0), 0) : 0;
    setIsModalVisible(true);
  }

  // const handleSubmit = async () => {
  //   try {
  //     setLoadingRequest(true)
  //    const values = await form.validateFields();
  //   setFormData(values);
  //   values.total_price_all = Object.keys(values).length === 0 ? '-' : values?.items?.length > 0 ? values?.items?.reduce((a,b) => a + (parseInt(b.total_price) || 0) ,0) : 0;

  //   values.items = values.items?.map((item, index) => ({
  //     ...item,
  //     total_price: !isNaN(parseFloat(totalHargaMaterial[index]))
  //     ? parseFloat(totalHargaMaterial[index].toString().replace(/,/g, ''))
  //     : 0,
  //     item_code: itemCode,
  //     banyak_pcs: parseFloat(banyakPcs),
  //   }));

  //     const response = await fetch(env.API + "/core/request-quotation", {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(values),
  //     });
  //     setLoadingRequest(false);
  //     if (response.status === 200) {
  //       waw.success('Data Berhasil Dikirim');
  //       form.resetFields();
  //       handleRemoveInput();
  //       setIsModalVisible(false);
  //     } else {
  //       console.error('Gagal Dikirim', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error submitting data:', error);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      setLoadingRequest(true);
      const values = await form.validateFields();
      setFormData(values);

      console.log('aftersetform',values)
      console.log('totalmat',totalHargaMaterial)
      values.total_price_all = Object.keys(values).length === 0 ? '-' : values?.items?.length > 0 ? values?.items?.reduce((a, b) => a + (parseInt(b.total_price) || 0), 0) : 0;

      values.items = values.items?.map((item, index) => ({
        ...item,
        ...(selectedCategory !== 'NON MATERIAL' ? {total_price: !isNaN(parseFloat(totalHargaMaterial[index]))
          ? parseFloat(totalHargaMaterial[index].toString().replace(/,/g, ''))
          : 0} : {}),
          item_code: itemCode[index],
        banyak_pcs: Number.isInteger(parseInt(banyakPcs[index], 10)) ? parseInt(banyakPcs[index], 10) : '',
      }));

      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (Array.isArray(values[key])) {
          values[key].forEach((item, index) => {
            Object.keys(item).forEach((subKey) => {
              formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
            });
          });
        } else {
          formData.append(key, values[key]);
        }
      });

      if (csv && csv !== undefined) {
        formData.append('filename', csv);
      }

      const response = await fetch(env.API + "/core/request-quotation", {
        method: 'POST',
        body: formData,
      });

      setLoadingRequest(false);

      if (response.status === 200) {
        form.resetFields();
        handleRemoveInput();
        handleRemoveInputMaterial();
        waw.success('Data Berhasil Dikirim');
        setIsModalVisible(false);
        window.location.reload();
      } else {
        console.error('Gagal Dikirim', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };





  const handlePricePerUnitChange = (value, index) => {
    const jumlahItem = form.getFieldsValue(['items', index, 'jumlah_permintaan']);
    console.log('handleprice change', jumlahItem.items, index)
    calculatePPNAndTotal(value, jumlahItem?.items[index]?.jumlah_permintaan, index);
  };

  const handleJumlahItemChange = (value, index) => {
    const hargaPerUnit = form.getFieldsValue(['items', index, 'price_per_unit']);
    console.log('handlejumlah item change', hargaPerUnit.items, index)
    calculatePPNAndTotal(hargaPerUnit?.items[index]?.price_per_unit, value, index);
  };


  const calculatePPNAndTotal = (price_per_unit, jumlah_permintaan, index) => {
    console.log('calculate',price_per_unit,jumlah_permintaan,index)
    if (price_per_unit && jumlah_permintaan) {
      const hargaPerUnitFloat = parseFloat(price_per_unit);
      const jumlahItemInt = parseInt(jumlah_permintaan);
      const totalHarga = hargaPerUnitFloat * jumlahItemInt;
      form.setFieldsValue({
        items: [
          ...form.getFieldValue('items').map((item, idx) =>
            idx === index
              ? { ...item, total_price: totalHarga.toFixed(0) }
              : item
          )
        ]
      });
    } else {
      form.setFieldsValue({
        items: [
          ...form.getFieldValue('items').map((item, idx) =>
            idx === index ? { ...item, total_price: undefined } : item
          )
        ]
      });
    }
  };

  const columns = [
    {
      title: 'Item Permintaan',
      dataIndex: 'item_permintaan',
      key: 'item_permintaan'
    },
    {
      title: 'Jumlah Permintaan',
      dataIndex: 'jumlah_permintaan',
      key: 'jumlah_permintaan'
    },
    {
      title: 'Satuan',
      dataIndex: 'satuan',
      key: 'satuan'
    },
    {
      title: 'Harga Per Unit',
      dataIndex: 'price_per_unit',
      key: 'price_per_unit',
      render: (price_per_unit) => `Rp.${price_per_unit?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
    },
    {
      title: 'Total Harga',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (total_price, record, index) => {
        if (total_price !== undefined && total_price !== null) {
          return `Rp.${total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
        }
        if (totalHargaMaterial && totalHargaMaterial[index] !== undefined && totalHargaMaterial[index] !== null) {
          return `Rp.${totalHargaMaterial[index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
        }
        return "-";
      }
    },
    // {
    //   title: 'Total Harga',
    //   dataIndex: 'total_price',
    //   key: 'total_price',
    //   render: (total_price,record,index) => {
    //     if (total_price) {
    //       return `Rp.${total_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    //     }
    //     if (totalHargaMaterial) {
    //       return `Rp.${totalHargaMaterial[index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    //     }
    //     return "-";
    //   }
    // },
    {
      title: 'Tanggal Pengiriman',
      dataIndex: 'request_date',
      key: 'request_date',
      render: (request_date) => formatDate(request_date),
    },
  ];

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const [datePicker, setDatePicker] = useState(null);
  const handleDateChange = (date, dateString) => {
    console.log('Selected Date:', dateString);
    handleIsCsvOpen(dateString, null);
    setDatePicker(dateString)
  };

  const [formTwo] = Form.useForm();
  const [isModalVisibleTwo, setIsModalVisibleTwo] = useState(false);
  const [formDataTwo, setFormDataTwo] = useState({});
  const [apiDataTwo, setApiDataTwo] = useState([]);
  const [totalHarga, setTotalHarga] = useState(null);

  // const onFinish = async (values) => {
  //   console.log('Received values:', values);
  //   setIsModalVisibleTwo(true);
  // }


  const handleModalShowTwo = () => {
    setIsModalVisibleTwo(true);
  };
  const handleModalCancelTwo = () => {
    setIsModalVisibleTwo(false);
    onRemove();
    setFileList([]);
  };
  const handleModalsimpan = () => {
    setIsModalVisibleTwo(false);
  };


  const handleModalOk = async () => {
    try {
      setLoading(true);
      const values = await formTwo.validateFields();
      setFormDataTwo(values);
      const response = await fetch(env.API + "/core/purchase-request", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      setLoading(false);
      if (response.status === 200) {
        waw.success('Data Berhasil Dikirim');
        formTwo.resetFields();
        handleRemoveInput();
        setIsModalVisibleTwo(false);
      } else {
        console.error('Gagal Dikirim', response.statusText);
      }
    } catch (error) {
      console.error('Kesalahan:', error.message);
    }
  };

  const [quotationNumber, setQuotationNumber] = useState('');
  const [totalPrice, setTotalPrice] = useState(null);
  useEffect(() => {
    if (quotationNumber !== '') {
      fetchTotalPrice(quotationNumber);
    }
  }, [quotationNumber]);

  const fetchTotalPrice = async (no_quotation) => {
    try {
      const response = await fetch(env.API + `/core/price-quotation?no_quotation=${no_quotation}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTotalPrice(data.data.total_price_all);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleQuotationChange = (event) => {
    setQuotationNumber(event.target.value);
  };

  const [inputCount, setInputCount] = useState(1);

  const handleAddInputItem = () => {
    if (inputCount < 15) {
      setInputCount(prevCount => prevCount + 1);
      getItemNo()
    } else {
      waw.error('Anda telah mencapai batas maksimal 15 item.');
    }
  };

  const handleRemoveInput = () => {
    if (inputCount > 1) {
      setInputCount(prevCount => prevCount - 1);
      const values = form.getFieldValue('items');
      form.setFieldsValue({
        items: values.filter((_, i) => i !== inputCount - 1)
      });
    }
  };

  const [inputCountMaterial, setInputCountMaterial] = useState(1);

  const handleAddInputItemMaterial = () => {
    setInputCountMaterial(prevCount => prevCount + 1);
    getItemNo();
};

  const handleRemoveInputMaterial = () => {
    if (inputCountMaterial > 1) {
      setInputCountMaterial(prevCount => prevCount - 1);
      const values = form.getFieldValue('items');
      form.setFieldsValue({
        items: values.filter((_, i) => i !== inputCountMaterial - 1)
      });
    }
  };

  const rulesEmail = () => [
    {
      type: 'email',
      message: 'Format email tidak valid',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || value.endsWith('@toyota.co.id')) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Alamat email hanya boleh dari "@toyota.co.id"'));
      },
    }),
  ];

  const [divisi, setDivisi] = useState([]);
  const getDivisi = async () => {
    try {
      const response = await axios.get(env.API + "/core/admin/divisi");
      setDivisi(response.data.data || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [departments, setDepartments] = useState([]);
  const [selectedDivisionId, setSelectedDivisionId] = useState("");
  const [isLoadingDepartment, setIsLoadingDepartment] = useState(false);
  const handleDivisionChange = (value) => {
    setSelectedDivisionId(value); 
    getDepartments(value); 
  };

  const getDepartments = async (divisionId) => {
    setIsLoadingDepartment(true);
    try {
      const response = await axios.get(env.API + "/core/admin/division-department", {
        params: { id: divisionId }
      });
      const departmentData = response.data.data?.department || [];
      setDepartments(departmentData); 
      } catch (error) {
        console.error("Error fetching Department data:", error);
        setDepartments([]); 
      }finally {
        setIsLoadingDepartment(false); 
      }
  };

  const [jenisKebutuhan, setKebutuhan] = useState([]);
  const getJenisKebutuhan = async () => {
    try {
      const response = await axios.get(env.API + "/core/admin/jenis-kebutuhan");
      setKebutuhan(response.data.data || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [itemKebutuhan, setItemKebutuhan] = useState([]);
  const getItemKebutuhan = async () => {
    try {
      const response = await axios.get(env.API + "/core/item-group");
      setItemKebutuhan(response.data.data.data || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [itemNo, setItemNo] = useState([[]]);
  // const getItemNo = async (item_group_name) => {
  //       try {
  //           const response = await axios.get(env.API + "/core/item-material", {
  //               params: { id: item_group_name }
  //           });
  //           setItemNo(response.data.data.data || []);
  //       } catch (error) {
  //           console.error("Error fetching item no:", error);
  //       }
  //   };

  // const getItemNo = async (item_group_name) => {
  //   setLoadingItemNo(true);
  //   try {
  //     const response = await axios.get(env.API + "/core/item-material", {
  //       params: { id: item_group_name }
  //     });
  //     setItemNo(response.data.data.data || []);
  //   } catch (error) {
  //     console.error("Error fetching item no:", error);
  //   } finally {
  //     setLoadingItemNo(false);
  //   }
  // };

  const getItemNo = async (item_group_name, indexMeterial) => {
    setLoadingItemNo(true);
    try {
      const response = await axios.get(env.API + "/core/item-material", {
        params: { id: item_group_name }
      });
      setItemNo(a => {
        const updatedList = [...a];
        updatedList[indexMeterial] = response.data.data.data || [];
        return updatedList;
      });
      // setItemNo(response.data.data.data || []);
    } catch (error) {
      console.error("Error fetching item no:", error);
    } finally {
      setLoadingItemNo(false);
    }
  };

  const [vendor, setVendor] = useState([]);
  const getVendor = async () => {
    try {
      const response = await axios.get(env.API + "/core/vendor");
      setVendor(response.data.data || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getJenisKebutuhan();
    getDivisi();
    getItemKebutuhan();
    getVendor();
    getDepartments();
  }, []);
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  const [selectedCategory, setSelectedCategory] = useState('NON MATERIAL');

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const [selectedItemGroupName, setSelectedItemGroupName] = useState("");
  // const [selectedItemNo, setSelectedItemNo] = useState("");
  const [selectedItemNo, setSelectedItemNo] = useState([]);
  // const [itemCode, setItemCode] = useState("");
  const [itemCode, setItemCode] = useState([]);
  const [loadingItemNo, setLoadingItemNo] = useState(false);

  // const handleItemKebutuhanChange = (value) => {
  //   handleIsCsvOpen(value, null);
  //   setSelectedItemGroupName(value);
  //   setSelectedItemNo("");
  //   setItemCode("");
  //   getItemNo(value);
  // };

  const handleItemKebutuhanChange = (value, indexMeterial) => {
    handleIsCsvOpen(value, null);
    setSelectedItemGroupName(value);
    // setSelectedItemNo("");
    setSelectedItemNo(prevPcs => {
      const a = [...prevPcs];
      a[indexMeterial] = true;
      return a;
    });
    // setItemCode("");
    getItemNo(value, indexMeterial);
    form.resetFields([['items', indexMeterial, 'item_no']]);
  };

  // const handleItemNoChange = (value) => {
  //   handleIsCsvOpen(value, null);
  //   setSelectedItemNo(value);
  //   const selectedItem = itemNo.find(item => item.item_name === value);
  //   if (selectedItem) {
  //     setItemCode(selectedItem.item_code);
  //   }
  // };

  const handleItemNoChange = (value, indexMeterial) => {
    handleIsCsvOpen(value, null);
    setSelectedItemNo(value);
    // const selectedItem = itemNo.find(item => item.item_name === value);
    const selectedItem = itemNo[indexMeterial] && itemNo[indexMeterial].find(item => item.item_name === value);
    if (selectedItem) {
      setItemCode(prevPcs => {
        const updatedItemCode = [...prevPcs];
        updatedItemCode[indexMeterial] = selectedItem.item_code;
        return updatedItemCode;
      });
      // setItemCode(selectedItem.item_code);
    }
  };

  // const [banyakPcs, setBanyakPcs] = useState('');
  const [hargaPerUnitMaterial, setHargaPerUnitMaterial] = useState('');
  const [totalHargaMaterial, setTotalHargaMaterial] = useState([]);
  const [banyakPcs, setBanyakPcs] = useState(Array(inputCountMaterial).fill(''));
  // const [hargaPerUnitMaterial, setHargaPerUnitMaterial] = useState(Array(inputCount).fill(0));
  // const [totalHargaMaterial, setTotalHargaMaterial] = useState(Array(inputCount).fill(0));

  // const handleJumlahChangeMaterial = async (e) => {
  //   const jumlah = e.target.value;

  //   if (!/^[0-9]*$/.test(jumlah)) {
  //     waw.error('Hanya diperbolehkan memasukkan angka!');
  //     return;
  //   }

  //   if (jumlah) {
  //     try {
  //       const response = await axios.get(env.API + "/core/pcs", {
  //         params: { 
  //           item_permintaan:selectedItemGroupName,
  //           jumlah_permintaan:jumlah
  //         }
  //       });

  //       const pcs = response.data.data.jumlah;
  //       setBanyakPcs(pcs);

  //       if (hargaPerUnitMaterial) {
  //         setTotalHargaMaterial(pcs * hargaPerUnitMaterial);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching banyak pcs:', error);
  //     }
  //   } else {
  //     setBanyakPcs('');
  //     setTotalHargaMaterial('');
  //   }
  // };

  const handleJumlahChangeMaterial = async (e, indexMeterial) => {
    const jumlah = e.target.value;

    if (!/^[0-9]*$/.test(jumlah)) {
      waw.error('Hanya diperbolehkan memasukkan angka!');
      return;
    }

    if (jumlah) {
      try {
        handleIsCsvOpen(jumlah, null);
        const response = await axios.get(env.API + "/core/pcs", {
          params: {
            item_permintaan: selectedItemGroupName,
            jumlah_permintaan: jumlah
          }
        });

        const pcs = response.data.data.jumlah;

        setBanyakPcs(prevPcs => {
          const updatedPcs = [...prevPcs];
          updatedPcs[indexMeterial] = pcs;
          return updatedPcs;
        });

        // if (hargaPerUnitMaterial) {
        //     setTotalHargaMaterial(pcs * hargaPerUnitMaterial);
        // }
        if (hargaPerUnitMaterial) {
          setTotalHargaMaterial(prevTotal => {
              const updatedTotal = [...prevTotal ];
              updatedTotal[indexMeterial] =  pcs[indexMeterial] * hargaPerUnitMaterial;
              return updatedTotal;
          });
        }
      } catch (error) {
        console.error('Error fetching banyak pcs:', error);
      }
    } else {
      setBanyakPcs(prevPcs => {
        const updatedPcs = [...prevPcs];
        updatedPcs[indexMeterial] = '';
        return updatedPcs;
      });
      setTotalHargaMaterial('');
    }
  };



  const handleHargaPerUnitChangeMaterial = (value, indexMeterial) => {
    console.log(value, banyakPcs, totalHargaMaterial)
    setHargaPerUnitMaterial(value);
    handleIsCsvOpen(value, null);

    if (banyakPcs) {
      // setTotalHargaMaterial(banyakPcs * value);
      setTotalHargaMaterial(prevTotal => {
        const updatedTotal = [...prevTotal];
        updatedTotal[indexMeterial] = banyakPcs[indexMeterial] * value;
        return updatedTotal;
      });
    }
  };

  const [fileList, setFileList] = useState([]);
  const [csv, setCsv] = useState(null);
  const onRemove = () => {
    console.log('file di hapus')
    setCsv(null);
    handleIsCsvInput(false);
  };
  useEffect(() => {
    console.log(isCsvInput)
  }, [isCsvInput]);
  const beforeUpload = (file) => {
    const csvFileToArray = (string) => {
      const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
      const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

      const array = csvRows.map((i) => {
        const values = i.split(",");
        const obj = csvHeader.reduce((object, header, index) => {
          object[header.replaceAll(" ", "_").toLowerCase()] = values[index];
          return object;
        }, {});
        return obj;
      });

      let arr = [];
      array.forEach((d) => {
        if (d.idr !== undefined) {
          let dates = d.transferred_at.split("/");
          console.log(`${dates[2]}-${dates[0]}-${dates[1]}`);
          let obj = {
            id: d.loan_id,
            transferred_at: moment(d.transferred_at).format("YYYY-MM-DD"),
          };
          arr.push(obj);
        }
      });
    };

    const fileReader = new FileReader();
    fileReader.onload = function (event) {
      const text = event.target.result;
      csvFileToArray(text);
    };

    fileReader.readAsText(file);
    console.log(file);
    setCsv(file);
    return false;
  };

  const handleChangeDragger = (info) => {
    console.log('ini adlaah',info)
    setFileList(info?.fileList);
    if (info.fileList.length > 0) {
      // File successfully uploaded
      handleIsCsvInput(true);
      setIsCsvDisabled(false);
    } else {
      handleIsCsvInput(false);
      setIsCsvDisabled(false);
    }
  };

  const resetInput = () => {
    form.resetFields();
    setIsCsvDisabled(false);
  }

  const fileName = 'Import Data Vendor';
  const downloadCSV = () => {
  const headers = [
    "item_permintaan", "price_per_unit", "jumlah_permintaan", "satuan", "request_date", "banyak_pcs", "item_no", "tgl_distribusi", "tgl_redeem", "keterangan", "item_code", "shift"
  ];

  const dummyData = [
    ["KEMEJA", "100000", "5", "PCS", "dd/mm/yyyy", "10", "KEMEJA LP L 01", "dd/mm/yyyy", "dd/mm/yyyy", "Test Import", "KEMEJA LP L 01", "RED SHIFT"],
    ["KEMEJA", "200000", "4", "PCS", "dd/mm/yyyy", "8", "K1", "dd/mm/yyyy", "dd/mm/yyyy", "Test Import", "K1", "NON SHIFT"],
  ];

  const rows = [
    headers.join(','),
    ...dummyData.map(row => row.join(','))
  ];

  const csvContent = "data:text/csv;charset=utf-8," + rows.join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

  const menu = (
    <Menu style={{ width: '200px' }}>
      <Menu.Item
        key="1"
        onClick={()=> handleModalShowTwo()}
      >
        Import Data Csv
      </Menu.Item>
      <Menu.Item key="2" 
      onClick={downloadCSV}
      >
       Download Format Import
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {activeTab === 1 && (
        <>
          <Form
            form={form}
            layout="vertical"
            name="request-form"
            onFinish={onFinishRequest}
            autoComplete="off"
          >
            <div className=''>
              <div className='header-from-input-wraper-content'>
                <div className=''>
                  <div className='header-from-input-wraper-title'>
                    <div className='header-form-wraper-border-inline'>
                      <div className='header-form-wraper-border'>
                        <div className='header-from-input-wraper-inline'>
                          <div className=''>
                            <h1 style={{ fontSize: '18px', fontWeight: '600', color: 'black' }}>Form Pengajuan</h1>
                            <p style={{ color: '#6F6C6C', fontSize: '13px' }}>Berikut ini dapat diinputkan data Nomor Pengajuan Pembelian dan Juga Harga kepada koperasi toyota.</p>
                          </div>

                          <Row gutter={[16, 16]} justify="start" style={{ marginTop: '30px' }}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                label="Kategori"
                                hasFeedback
                                name="category"
                                rules={rulesGeneral()}
                                initialValue="NON MATERIAL"
                              >
                                <Select style={{ width: "100%" }}
                                  onChange={handleCategoryChange}
                                  placeholder="Pilih Kategori"
                                  defaultValue={'NON MATERIAL'}
                                >
                                  <Option value="" disabled>
                                    -- Pilih --
                                  </Option>
                                  <Option value="NON MATERIAL">NON MATERIAL</Option>
                                  <Option value="MATERIAL">MATERIAL</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                hasFeedback
                                label="Judul Pengajuan"
                                name="judul_pengajuan"
                                rules={rulesGeneral()}
                              >
                                <Input
                                  placeholder="Masukan Judul"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                hasFeedback
                                label="Pemohon"
                                name="applicant"
                                rules={rulesGeneral()}
                              >
                                <Input
                                  placeholder="Masukan Nama Pemohon"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                hasFeedback
                                label="Alamat Email"
                                name="email_address"
                                rules={rulesGeneral()}
                              >
                                <Input
                                  placeholder="Masukan Alamat Email"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                hasFeedback
                                name="division"
                                label="Divisi"
                                rules={rulesGeneral()}
                              >
                                <Select 
                                style={{ width: "100%" }} 
                                placeholder="Pilih Salah Satu"
                                showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option?.children.toLowerCase().includes(input.toLowerCase())
                                  }
                                  onChange={handleDivisionChange}
                                >
                                  <Option value="" disabled>
                                    -- Pilih --
                                  </Option>
                                  {divisi.map((nama_divisi) => {
                                    return (
                                      <Option value={nama_divisi.id}>
                                        {nama_divisi.nama_divisi}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            {selectedCategory === 'MATERIAL' && (
                              <>
                            <Col xs={24} sm={12}>
                            <Form.Item
                              hasFeedback
                              label="Department"
                              name="department"
                              rules={rulesGeneral()}
                            >
                              <Select 
                              style={{ width: "100%" }}
                              placeholder="Pilih Department"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option?.children.toLowerCase().includes(input.toLowerCase())
                              }
                              disabled={!selectedDivisionId}
                              loading={isLoadingDepartment}
                              >
                                <Option value="" disabled>
                                  -- Pilih --
                                </Option>
                                {departments.map((item) => (
                                  <Option key={item.id} value={item.name}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          </>
                            )}
                            <Col xs={24} sm={12}>
                              <Form.Item
                                hasFeedback
                                name="jenis_kebutuhan"
                                label="Jenis Kebutuhan"
                                rules={rulesGeneral()}
                              >
                                <Select style={{ width: "100%" }} placeholder="Pilih Salah Satu">
                                  <Option value="" disabled>
                                    -- Pilih --
                                  </Option>
                                  {jenisKebutuhan.map((jenis_kebutuhan) => {
                                    return (
                                      <Option value={jenis_kebutuhan.id}>
                                        {jenis_kebutuhan.nama_jenis_kebutuhan}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            {selectedCategory === 'MATERIAL' && (
                              <>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    hasFeedback
                                    label="Lokasi"
                                    name="location"
                                    rules={rulesGeneral()}
                                  >
                                    <Select style={{ width: "100%" }}
                                      placeholder="Pilih Lokasi">
                                      <Option value="" disabled>
                                        -- Pilih --
                                      </Option>
                                      <Option value="HEAD OFFICE">Head Office</Option>
                                      <Option value="KARAWANG 1">Karawang 1</Option>
                                      <Option value="KARAWANG 2">Karawang 2</Option>
                                      <Option value="KARAWANG 3">Karawang 3</Option>
                                      <Option value="SUNTER 1">Sunter 1</Option>
                                      <Option value="SUNTER 2">Sunter 2</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24}>
                                  <Form.Item
                                    hasFeedback
                                    label="Vendor"
                                    name="vendor"
                                    rules={rulesGeneral()}
                                  >
                                    <Select style={{ width: "100%" }}
                                      placeholder="Pilih Vendor">
                                      <Option value="" disabled>
                                        -- Pilih --
                                      </Option>
                                      {vendor.map((nama_vendor) => {
                                        return (
                                          <Option value={nama_vendor.nama_vendor}>
                                            {nama_vendor.nama_vendor}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </Row>
                          <div className='header-from-input-wraper-line-loop mt-3'></div>
                          {selectedCategory === 'NON MATERIAL' && (
                            <>
                              {[...Array(inputCount)].map((_, index) => (
                                <>
                                  <div style={{ display: 'flex' }}>
                                    <h1 style={{ fontSize: '13px', marginTop: '28px', marginLeft: '0px' }}>{`Item ${index + 1}`}</h1>
                                  </div>
                                  <Row gutter={[16, 16]} justify="start" style={{ marginTop: '10px' }}>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Item Kebutuhan</h5>
                                      <Form.Item
                                        hasFeedback
                                        // name="item_permintaan"
                                        name={['items', index, 'item_permintaan']}
                                        rules={rulesGeneral()}
                                      >
                                        <Input
                                          placeholder="Masukan Item Kebutuhan"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Jumlah</h5>
                                      <Form.Item
                                        hasFeedback
                                        // name="jumlah_permintaan"
                                        name={['items', index, 'jumlah_permintaan']}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Jumlah permintaan tidak boleh kosong",
                                          },
                                          {
                                            pattern: /^[0-9]*$/,
                                            message: "Jumlah permintaan hanya boleh berisi angka",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="Masukan Jumlah"
                                          onChange={(e) => handleJumlahItemChange(e.target.value, index)}
                                          onKeyPress={(e) => {
                                            const charCode = e.which ? e.which : e.keyCode;
                                            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                              e.preventDefault();
                                              waw.error('Hanya diperbolehkan memasukkan angka!');
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Satuan</h5>
                                      <Form.Item
                                        hasFeedback
                                        // name="satuan"
                                        name={['items', index, 'satuan']}
                                        rules={rulesGeneral()}
                                      >
                                        <Select style={{ width: "100%" }} placeholder="Masukan Satuan">
                                          <Option value="" disabled>
                                            -- Pilih --
                                          </Option>
                                          <Option value="PCS">PCS</Option>
                                          <Option value="UNIT">UNIT</Option>
                                          <Option value="PACK">PACK</Option>
                                          <Option value="LOT">LOT</Option>
                                          <Option value="AU">AU</Option>
                                          <Option value="TBG">TBG</Option>
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Harga Per Unit</h5>
                                      <Form.Item
                                        hasFeedback
                                        // name="price_per_unit"
                                        name={['items', index, 'price_per_unit']}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Harga Per Unit tidak boleh kosong",
                                          },
                                          {
                                            pattern: /^[0-9]*$/,
                                            message: "Harga Per Unit hanya boleh berisi angka",
                                          },
                                        ]}
                                        initialValue={''}

                                      >
                                        <InputNumber
                                          style={{ width: '100%' }}
                                          controls={false}
                                          {...props}
                                          formatter={(value) => formatterNumber(value)}
                                          parser={(value) => parserNumber(value)}
                                          onChange={(value) => handlePricePerUnitChange(value ? value : [], index)}
                                          placeholder="Masukan Harga Per Unit"
                                          onKeyPress={(e) => {
                                            const charCode = e.which ? e.which : e.keyCode;
                                            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                              e.preventDefault();
                                              waw.error('Hanya diperbolehkan memasukkan angka!');
                                            }
                                          }}
                                        />
                                        {/* <Input
                            formatter={toRp}
                             onChange={(e) => handlePricePerUnitChange(e.target.value)}
                            /> */}
                                        {/* <InputCurrencyHelper
                            callback={(value) => handlePricePerUnitChange(value ? value : [])}
                            values='price_per_unit'
                            /> */}
                                      </Form.Item>
                                    </Col>
                                    {/* <Col xs={24} sm={12} md={8}>
                        <h5 style={{ fontWeight: "600",fontSize:"13px" }}>Fee</h5>
                        <Form.Item
                            hasFeedback
                            placeholder="PPN"
                            name="ppn"
                            rules={rulesGeneral()}
                        >
                            <InputNumber 
                            style={{width:'100%'}}
                            {...props}
                            formatter={(value) => formatterNumber(value)}
                            parser={(value) => parserNumber(value)}
                            disabled
                            />
                        </Form.Item>
                        </Col> */}
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Total Harga</h5>
                                      <Form.Item
                                        hasFeedback
                                        placeholder="Input Total Harga"
                                        name={['items', index, 'total_price']}
                                        rules={rulesGeneral()}
                                      >
                                        <InputNumber
                                          style={{ width: '100%' }}
                                          {...props}
                                          formatter={(value) => formatterNumber(value)}
                                          parser={(value) => parserNumber(value)}
                                          disabled
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Tanggal Pengiriman</h5>
                                      <Form.Item
                                        name={['items', index, 'request_date']}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Tanggal pengiriman tidak boleh kosong",
                                          },
                                        ]}
                                        initialValue={datePicker ? dayjs(datePicker, 'YYYY-MM-DD') : ''}
                                      >
                                        <DatePicker
                                          picker="date"
                                          onChange={handleDateChange}
                                          placeholder='Pilih Tanggal'
                                          format="YYYY-MM-DD"
                                          style={{ width: "100%" }}
                                          disabledDate={disabledDate}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </>
                              ))}
                            </>
                          )}

                          {selectedCategory === 'MATERIAL' && (
                            <>
                             <Dropdown overlay={menu}>
                             <p style={{marginTop:'20px',cursor:'pointer',color: '#03753F'}}>
                            <img
                                    className=""
                                    src="/img/import.png"
                                    style={{ marginBottom: '0px' }}
                                    alt="logo"
                                  />
                              <span style={{marginLeft:'10px'}}>Import Data Csv</span>
                            </p>
                            </Dropdown>
                            {/* <p style={{marginTop:'20px',cursor:'pointer',color: '#03753F'}} onClick={()=> handleModalShowTwo()}>
                            <img
                                    className=""
                                    src="/img/import.png"
                                    style={{ marginBottom: '0px' }}
                                    alt="logo"
                                  />
                              <span style={{marginLeft:'10px'}}>Import Data Csv</span>
                            </p> */}
                            {/* <Button onClick={()=> handleModalShowTwo()} style={{ marginTop: '10px', backgroundColor: 'transparent', color: '#03753F',border:'none',textAlign:'start' }}>
                              Import Data Csv
                              <img
                                    className=""
                                    src="/img/import.png"
                                    style={{ marginBottom: '10px',marginLeft:'15px' }}
                                    alt="logo"
                                  />
                            </Button> */}
                            <Modal
                              title={false}
                              visible={isModalVisibleTwo}
                              closeIcon={false}
                              footer={false}
                            >
                              <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Import Csv</h5>
                              <Form.Item
                              hasFeedback
                              // label="Upload CSV"
                              // name="filename"
                              // rules={rulesGeneral()}
                            >

                              <Dragger
                                maxCount={1}
                                beforeUpload={beforeUpload}
                                onRemove={onRemove}
                                onChange={handleChangeDragger}
                                disabled={isCsvDisabled}
                                // className="hide-remove-icon"
                                multiple={false}
                                fileList={fileList}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                style={{ border: '2px solid #e8e8e8', borderRadius: '4px', padding: '20px', textAlign: 'center', background: 'white' }}
                              >
                                <p className="ant-upload-drag-icon">
                                  <img
                                    className=""
                                    src="/img/uploadlampiran.png"
                                    style={{ marginTop: '10px', marginBottom: '10px' }}
                                    alt="logo"
                                  />
                                </p>
                                <p className="ant-upload-text">
                                  Letakan dokumen Anda di sini atau <span style={{ color: '#03753F' }}>Pilih Dokumen</span>
                                </p>
                                <p className="ant-upload-hint">
                                  Pilih format dokumen dengan format .csv
                                </p>
                              </Dragger>
                            </Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                              <Button
                                type="primary"
                                onClick={() => handleModalsimpan()}
                                style={{ backgroundColor: 'transparent', color: '#03753F' }}
                              >
                                Simpan
                              </Button>
                              <Button
                                type="default"
                                onClick={() => handleModalCancelTwo()}
                                style={{ color: '#FF0000' }}
                              >
                                Batal
                              </Button>
                            </div>
                          </Modal>
                            
                            {/* <Button type="primary" onClick={()=> resetInput()} style={{ marginRight: '10px', backgroundColor: 'transparent', color: '#03753F' }}>Reset</Button> */}
                            </>
                          )}
                          {selectedCategory === 'MATERIAL' && (
                            <>
                              {[...Array(inputCountMaterial)].map((_, indexMeterial) => (
                                <>
                                  <div style={{ display: 'flex' }}>
                                    <h1 style={{ fontSize: '13px', marginTop: '20px', marginLeft: '0px' }}>{`Item ${indexMeterial + 1}`}</h1>
                                  </div>
                                  <Row gutter={[16, 16]} justify="start" style={{ marginTop: '10px' }}>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Item Kebutuhan</h5>
                                      <Form.Item
                                        hasFeedback
                                        name={['items', indexMeterial, 'item_permintaan']}
                                      // rules={rulesGeneral()}
                                      >
                                        <Select style={{ width: "100%" }}
                                          placeholder="Pilih Item Kebutuhan"
                                          // onChange={handleItemKebutuhanChange}
                                          onChange={(e) => handleItemKebutuhanChange(e, indexMeterial)}
                                          value={selectedItemGroupName}
                                          key={`itemKebutuhan-${selectedItemGroupName}`}
                                          disabled={isCsvInput}
                                          showSearch
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                          option?.children.toLowerCase().includes(input.toLowerCase())
                                          }
                                        >
                                          <Option value="" disabled>
                                            -- Pilih --
                                          </Option>
                                          {itemKebutuhan.map((item_kebutuhan) => {
                                            return (
                                              <Option value={item_kebutuhan.item_group_name}>
                                                {item_kebutuhan.name}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Item No.</h5>
                                      <Form.Item
                                        hasFeedback
                                        name={['items', indexMeterial, 'item_no']}
                                      // rules={rulesGeneral()}
                                      >
                                        <Select style={{ width: "100%" }}
                                          placeholder="Item No."
                                          // onChange={handleItemNoChange}
                                          onChange={(e) => handleItemNoChange(e, indexMeterial)}
                                          // value={selectedItemNo}
                                          value={selectedItemNo[indexMeterial]}
                                          key={`itemNo-${selectedItemGroupName}`}
                                          loading={loadingItemNo}
                                          disabled={!selectedItemGroupName || isCsvInput}
                                          showSearch
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                          option?.children.toLowerCase().includes(input.toLowerCase())
                                          }
                                        >
                                          <Option value="" disabled>
                                            -- Pilih --
                                          </Option>
                                          {itemNo[indexMeterial] && itemNo[indexMeterial].map((item) => (
                                            <Option key={item.item_name} value={item.item_name}>
                                              {item.item_name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} style={{ display: 'none' }}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Item Code</h5>
                                      <Form.Item
                                        hasFeedback
                                      // name={['items', indexMeterial, 'item_code']}
                                      // rules={rulesGeneral()}
                                      >
                                        <Input
                                          placeholder="Masukan Code"
                                          // value={itemCode}
                                          value={itemCode[indexMeterial]}
                                          readOnly
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Jumlah</h5>
                                      <Form.Item
                                        hasFeedback
                                        name={['items', indexMeterial, 'jumlah_permintaan']}
                                      // rules={[
                                      //   {
                                      //     required: true,
                                      //     message: "Jumlah permintaan tidak boleh kosong",
                                      //   },
                                      //   {
                                      //     pattern: /^[0-9]*$/,
                                      //     message: "Jumlah permintaan hanya boleh berisi angka",
                                      //   },
                                      // ]}
                                      >
                                        <Input
                                          placeholder="Masukan Jumlah"
                                          // onChange={handleJumlahChangeMaterial}
                                          onChange={(e) => handleJumlahChangeMaterial(e, indexMeterial)}
                                          // disabled={isCsvInput}
                                          disabled={!selectedItemNo[indexMeterial] || isCsvInput}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Banyak PCS</h5>
                                      <Form.Item
                                        hasFeedback
                                      // name={['items', indexMeterial, 'banyak_pcs']}
                                      // rules={rulesGeneral()}
                                      >
                                        <Input
                                          placeholder="Masukan PCS"
                                          value={banyakPcs[indexMeterial] || ''}
                                          // value={banyakPcs}
                                          readOnly
                                          disabled={isCsvInput}
                                        />
                                      </Form.Item>
                                    </Col>
                                     <Col xs={24} sm={12} md={8}>
                                      <Form.Item
                                        hasFeedback
                                        label="Shift"
                                        name={['items', indexMeterial, 'shift']}
                                      >
                                        <Select style={{ width: "100%" }}
                                          placeholder="Pilih Shift"
                                          disabled={isCsvInput}
                                          >
                                          <Option value="" disabled>
                                            -- Pilih --
                                          </Option>
                                          <Option value="RED SHIFT">Red Shift</Option>
                                          <Option value="NON SHIFT">Non Shift</Option>
                                          <Option value="WHITE SHIFT">White Shift</Option>
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Satuan</h5>
                                      <Form.Item
                                        hasFeedback
                                        name={['items', indexMeterial, 'satuan']}
                                      // rules={rulesGeneral()}
                                      >
                                        <Select
                                          style={{ width: "100%" }}
                                          placeholder="Masukan Satuan"
                                          disabled={isCsvInput}
                                        >
                                          <Option value="" disabled>
                                            -- Pilih --
                                          </Option>
                                          <Option value="PCS">PCS</Option>
                                          <Option value="UNIT">UNIT</Option>
                                          <Option value="PACK">PACK</Option>
                                          <Option value="LOT">LOT</Option>
                                          <Option value="AU">AU</Option>
                                          <Option value="TBG">TBG</Option>
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Harga Per Unit</h5>
                                      <Form.Item
                                        hasFeedback
                                        name={['items', indexMeterial, 'price_per_unit']}
                                        // rules={[
                                        //   {
                                        //     required: true,
                                        //     message: "Harga Per Unit tidak boleh kosong",
                                        //   },
                                        //   {
                                        //     pattern: /^[0-9]*$/,
                                        //     message: "Harga Per Unit hanya boleh berisi angka",
                                        //   },
                                        // ]}
                                        initialValue={''}

                                      >
                                        <InputNumber
                                          style={{ width: '100%' }}
                                          controls={false}
                                          {...props}
                                          formatter={(value) => formatterNumber(value)}
                                          parser={(value) => parserNumber(value)}
                                          onChange={(value) => handleHargaPerUnitChangeMaterial(value ? value : [], indexMeterial)}
                                          placeholder="Masukan Harga Per Unit"
                                          onKeyPress={(e) => {
                                            const charCode = e.which ? e.which : e.keyCode;
                                            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                              e.preventDefault();
                                              waw.error('Hanya diperbolehkan memasukkan angka!');
                                            }
                                          }}
                                          disabled={isCsvInput}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Total Harga</h5>
                                      <Form.Item
                                        hasFeedback
                                        placeholder="Input Total Harga"
                                      // name={['items', indexMeterial, 'total_price']}
                                      // rules={rulesGeneral()}
                                      >
                                        <InputNumber
                                          style={{ width: '100%' }}
                                          {...props}
                                          value={totalHargaMaterial[indexMeterial] || ''}
                                          // value={totalHargaMaterial}
                                          formatter={(value) => formatterNumber(value)}
                                          parser={(value) => parserNumber(value)}
                                          disabled
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Tanggal Pengiriman</h5>
                                      <Form.Item
                                        name={['items', indexMeterial, 'request_date']}
                                        // rules={[
                                        //     {
                                        //     required: true,
                                        //     message: "Tanggal pengiriman tidak boleh kosong",
                                        //     },
                                        // ]}
                                        initialValue={datePicker ? dayjs(datePicker, 'YYYY-MM-DD') : ''}
                                      >
                                        <DatePicker
                                          picker="date"
                                          onChange={handleDateChange}
                                          placeholder='Pilih Tanggal'
                                          format="YYYY-MM-DD"
                                          style={{ width: "100%" }}
                                          disabledDate={disabledDate}
                                          disabled={isCsvInput}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Tanggal Distribusi</h5>
                                      <Form.Item
                                        name={['items', indexMeterial, 'tgl_distribusi']}
                                        // rules={[
                                        //     {
                                        //     required: true,
                                        //     message: "Tanggal distribusi tidak boleh kosong",
                                        //     },
                                        // ]}
                                        initialValue={datePicker ? dayjs(datePicker, 'YYYY-MM-DD') : ''}
                                      >
                                        <DatePicker
                                          picker="date"
                                          onChange={handleDateChange}
                                          placeholder='Pilih Tanggal'
                                          format="YYYY-MM-DD"
                                          style={{ width: "100%" }}
                                          disabledDate={disabledDate}
                                          disabled={isCsvInput}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                      <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Tanggal Redeem</h5>
                                      <Form.Item
                                        name={['items', indexMeterial, 'tgl_redeem']}
                                        // rules={[
                                        //     {
                                        //     required: true,
                                        //     message: "Tanggal redeem tidak boleh kosong",
                                        //     },
                                        // ]}
                                        initialValue={datePicker ? dayjs(datePicker, 'YYYY-MM-DD') : ''}
                                      >
                                        <DatePicker
                                          picker="date"
                                          onChange={handleDateChange}
                                          placeholder='Pilih Tanggal'
                                          format="YYYY-MM-DD"
                                          style={{ width: "100%" }}
                                          disabledDate={disabledDate}
                                          disabled={isCsvInput}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <h5 style={{ fontWeight: "600", fontSize: "13px" }}>Keterangan</h5>
                                  <Form.Item
                                    name={['items', indexMeterial, 'keterangan']}
                                  // rules={[
                                  // {
                                  // required: true,
                                  // message: "Tanggal keterangan tidak boleh kosong",
                                  // },
                                  // ]}      
                                  >
                                    <TextArea
                                      autoSize={{ minRows: 10, maxRows: 20 }}
                                      placeholder=" "
                                      style={{ height: 300 }}
                                      disabled={isCsvInput}
                                      onChange={(e) => handleIsCsvOpen(e)}
                                    />
                                  </Form.Item>
                                </>
                              ))}

                            </>
                          )}
                          {selectedCategory === 'MATERIAL' && (
                          <Form.Item>
                            <Button type="primary" onClick={handleAddInputItemMaterial} style={{ marginRight: '10px', backgroundColor: 'transparent', color: '#03753F' }} disabled={isCsvInput}>+   TAMBAH ITEM</Button>
                            <Button type="danger" onClick={handleRemoveInputMaterial} disabled={inputCountMaterial === 1}>HAPUS ITEM</Button>
                            <div style={{ padding: '20px' }}></div>
                          </Form.Item>
                          )}
                          {selectedCategory === 'NON MATERIAL' && (
                           <Form.Item>
                            <Button type="primary" onClick={handleAddInputItem} style={{ marginRight: '10px', backgroundColor: 'transparent', color: '#03753F' }} disabled={isCsvInput}>+   TAMBAH ITEM</Button>
                            <Button type="danger" onClick={handleRemoveInput} disabled={inputCount === 1}>HAPUS ITEM</Button>
                            <div style={{ padding: '20px' }}></div>
                          </Form.Item>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=''>
              <div className='footer-input-submit-wraper'>
                <div className='footer-input-submit-wraper-button'>
                  <Row gutter={[16, 16]} justify="space-between" align="top">
                    <Col xs={24} sm={12} lg={8}>
                      {/* <button className='button-delete-item-wraper' style={{cursor:'pointer'}}>Hapus Item</button> */}
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                      <button
                        className='button-send-request-wraper'
                        style={{ cursor: 'pointer' }}
                        htmlType="submit"
                      >KIRIM PENGAJUAN</button>

                      <Modal
                        closable={false}
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        width={900}
                        footer={null}
                      >
                        <>
                          <div>
                            <h1 className='modal-text-action-wraper' style={{ textAlign: 'center' }}>Konfirmasi Pengajuan</h1>
                            <h1 className='modal-text-action-wraper' style={{ textAlign: 'center', fontSize: '14px', fontWeight: '400', marginBottom: '25px' }}>Jika Anda memilih Kirim Pengajuan akan dikirim. atau Klik edit untuk mengubah data pengajuan.</h1>
                          </div>
                          {!isCsvInput ?
                            <>
                            <Table
                            dataSource={formData?.items}
                            columns={columns}
                            pagination={false}
                          />
                          <div style={{ marginLeft: 'auto' }}>
                            {/* <h1 style={{fontWeight:'600',fontSize:'16px',marginTop:'10px'}}>Total : {`Rp.${toRp(formData.total_price_all)}`}</h1>  */}
                            {/* <h1 style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
                                Total : {formData.total_price_all 
                                  ? `Rp.${toRp(formData.total_price_all)}` 
                                  : totalHargaMaterial 
                                    ? `Rp.${toRp(totalHargaMaterial)}`
                                    : '-'}
                              </h1> */}
                            <h1 style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
                              Total: {formData.total_price_all                                ? `Rp.${toRp(formData.total_price_all)}`
                                : Array.isArray(totalHargaMaterial) && totalHargaMaterial.length > 0
                                  ? `Rp.${toRp(totalHargaMaterial.reduce((a, b) => a + b, 0))}`
                                  : '-'}
                            </h1>
                          </div>
                            </>
                            :
                            <>
                              <div style={{textAlign:'center'}}>
                                  <img
                                    className=""
                                    src="/img/sentmessage.png"
                                    style={{marginTop:'10px',marginBottom:'10px'}}
                                    alt="logo"
                                  />
                                  <h5 style={{ fontWeight: "600",fontSize:"18px",color:'black' }}>Penawaran Akan Di Kirim</h5>
                                  </div>
                            </>
                          }
                          <Row gutter={[16, 16]} justify="space-between" align="top">
                            <Col xs={24} sm={12} lg={8}>
                              {/* Item 1 - Pojok Kiri */}
                              <div style={{ padding: '15px', textAlign: 'center' }}>
                                <button onClick={handleCancel} className='button-save-draft-wraper' style={{ cursor: 'pointer' }}>EDIT REQUEST</button>
                              </div>
                            </Col>
                            <Col xs={24} sm={12} lg={8}>
                              {/* Item 2 - Pojok Kanan */}
                              <div style={{ padding: '15px', textAlign: 'center' }}>
                                <button key='confirm' onClick={handleSubmit} disabled={loading} className='button-modal-sendreq-wraper' style={{ cursor: 'pointer' }}>
                                  {loadingRequest ? (
                                    <Spin
                                      indicator={
                                        <LoadingOutlined
                                          style={{ fontSize: 24, color: "white" }}
                                          spin
                                        />
                                      }
                                    />
                                  ) : (
                                    "KIRIM REQUEST"
                                  )}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </>
                      </Modal>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </>
  )
}

export default RequestMaterial
